import React from "react";
import { Modal } from "semantic-ui-react";
import { Paragraph1 } from "@styles/Global.styles";
import { InfoModalButton } from "./InfoModal.styles";

interface InfoModalProps{
    children?: any,
    modalOpen: boolean
    title:string
    content: string
    showCloseButton?: boolean,
    onClose: Function
}
export default class InfoModal extends React.Component<InfoModalProps>{

    handleClose =() => {
        this.props.onClose();
    }
    render(){
        const {title, content} = this.props;
        return (
            <Modal open={this.props.modalOpen} onClose={this.handleClose}>
                <Modal.Header>{title}</Modal.Header>
                <Modal.Content>
                    <Paragraph1>
                        {content}
                    </Paragraph1>
                    {this.props.children}
                </Modal.Content>
                {
                    this.props.showCloseButton ? 
                    <Modal.Actions>
                        <InfoModalButton
                            negative
                            icon='close'
                            labelPosition='right'
                            content="CLOSE"
                            onClick={this.handleClose}
                        />
                    </Modal.Actions> :
                    <Modal.Actions>
                        <InfoModalButton
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content="OK"
                            onClick={this.handleClose}
                        />
                    </Modal.Actions>
                }

            </Modal>
        )
    }
}