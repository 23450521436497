import { graphql } from "gatsby";
import { Layout } from "@components/Layout";
import { mapToSEO, SEO } from "@components/SEO";
import React from "react";
import { GenericBannerContainer } from "@components/GenericBannerContainer";
import { ShoppingCartBlock } from "@components/ShoppingCart";
import { isMobile } from "@utils/Helpers";

const queryString = require("query-string")

const ShoppingCartPage = (props: any) =>{
    const {location, data} = props;
    const {elements} = data.kontentItemShoppingCart;
    const apiUrl = data.site.siteMetadata.apiUrl;
    const heading = elements.heading.value;
    const unavailableTitle = elements.plate_unavailable_title.value;
    const unavailableMessage = elements.plate_unavailable_message.value;
    const seo = mapToSEO(elements);
    const basketId = queryString.parse(location.search.toLowerCase()).cartid

    if(isMobile() === undefined){
      return null;
    }

    return (
        <Layout version="simple" currentStep={2} location={location}>
            <SEO {...seo} />
            <GenericBannerContainer
              padding={{
                mobile: {
                    top: 20,
                    bottom: 20
                },
                desktop: {
                    top: 40,
                    bottom: 40
                }
              }}
              backgroundColor='black'>
                <ShoppingCartBlock darkTheme={true} title={heading} apiUrl={apiUrl} unavailableTitle={unavailableTitle} unavailableMessage={unavailableMessage} basketIdFromUrl={basketId}/>
            </GenericBannerContainer>
        </Layout>
    )
}


export const query = graphql`
{
  site {
    siteMetadata {
      apiUrl
    }
  }
    kontentItemShoppingCart{
      elements {
        heading{
          value
        }
        page_info___checkout__title{
          value
        }
        page_info___checkout__progress{
          value{
            codename
            name
          }
        }
        heading{
          value
        }
        seo__noindex {
          value {
            name
            codename
          }
        }
        seo__nofollow {
          value {
            name
            codename
          }
        }
        seo__page_title {
          value
        }
        seo__page_description {
          value
        }
        url {
          value
        }
        plate_unavailable_title{
          value
        }
        plate_unavailable_message{
          value
        }
      }
    }
  }
`
export default ShoppingCartPage